import React, { useEffect, useState } from 'react';
import { Avatar, Image, Space, Tabs, Tag, Typography } from 'antd';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined, CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

import {
  ProfileBanner,
  BalanceProfileIcon,
  UserIcon,
  BannerProfileMobile,
} from 'styles/image';
import { MetaButton } from 'app/components/Forms';
import { PATH } from 'app/constants/path';
import { MetaHelmet } from 'app/components/MetaHelmet';
import { selectAuth } from 'app/pages/AuthPage/slice/selectors';
import { getFirstLetter } from 'utils/helpers';
import { MetaLoading } from 'app/components';
import { useProfileSlice } from '../slice';
import { selectProfile } from '../slice/selectors';
import { useAuthSlice } from 'app/pages/AuthPage/slice';
import FollowedStreamer from '../components/FollowedStreamer';
import BalanceLists, { PAGE_SIZE } from '../components/BalanceLists';
import { pointActivityRequest } from 'app/apis/gift';
import { _log } from 'utils/_log';

export function ProfileView() {
  const isMobile = useMediaQuery({ maxWidth: '703px' });
  const isTablet = useMediaQuery({ maxWidth: '1024px' });
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [tabKey, setTabKey] = useState('1');
  const [activities, setActivities] = useState<any>([]);
  const [sumOfActivity, setSumOfActivity] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isCopy, setIsCopy] = useState(false);

  const { actions } = useProfileSlice();
  const authActions = useAuthSlice();
  const { currentUser, isUpdatePurchasePoint } = useSelector(selectAuth);
  const { currentPerson, followerLists, followingLists, labels } =
    useSelector(selectProfile);

  let user: any = null;
  const { id } = params;
  let isMySelf = !id;

  const tabs: any = [
    {
      key: '1',
      name: 'フォローしている配信者',
      icon: UserIcon,
    },
    {
      key: '2',
      name: 'ポイント残高',
      icon: BalanceProfileIcon,
    },
  ];

  const [isFollow, setIsFollow] = useState(user?.followed);

  useEffect(() => {
    fetchPerson(id);
  }, [id]);

  if (id) {
    user = currentPerson;
  } else {
    user = currentUser;
  }

  const fetchPerson = id => {
    if (id) {
      dispatch(actions.getPerson({ id }));
    } else {
      dispatch(authActions.actions.getUser({}));
    }
  };

  const fetchFollowList = id => {
    dispatch(actions.getFollowerList({ id }));
    dispatch(actions.getFollowingList({ id }));
  };

  useEffect(() => {
    if (user) {
      let newId = id ? currentPerson?.id : currentUser?.id;
      setIsFollow(user?.followed);
      fetchFollowList(newId);
    }
  }, [user]);

  const fetchPointActivity = ({ page }) => {
    pointActivityRequest({ page: page, limit: PAGE_SIZE })
      .then((res: any) => {
        if (res.statusCode === 200) {
          setActivities(res.data.rows);
          setSumOfActivity(res.data.count);
          setIsLoading(false);
        }
      })
      .catch(err => {
        setIsLoading(false);
        _log('fetchPointActivity error: ', err, 'error');
      });
  };

  useEffect(() => {
    fetchPointActivity({ page: currentPage });
  }, []);

  useEffect(() => {
    if (isUpdatePurchasePoint) {
      fetchPointActivity({ page: 1 });
      setCurrentPage(1);
      dispatch(authActions.actions.setIsUpdatePurchasePoint(false));
    }
  }, [isUpdatePurchasePoint]);

  const handleFollowed = async isFollowed => {
    try {
      setIsLoading(true);
      if (isFollowed) {
        dispatch(actions.unFollowed({ target: user?.id }));
      } else {
        dispatch(actions.followed({ target: user?.id }));
      }

      setTimeout(() => {
        fetchPerson(user?.id);
        setIsFollow(!isFollowed);
        setIsLoading(false);
      }, 800);
    } catch (error) {
      setIsLoading(false);
      console.error('An error occurred in handleFollowed:', error);
    }
  };

  const handleChangeTab = key => {
    setTabKey(key);
  };

  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 2000);
    }
  }, [isCopy]);

  if (!user) {
    return <MetaLoading loading />;
  }

  return (
    <Wrapper isMySelf={isMySelf}>
      <MetaHelmet />
      <MetaLoading loading={isLoading} />
      <div className="profile-banner">
        <Image
          src={isTablet ? BannerProfileMobile : ProfileBanner}
          height={isMobile ? 162 : isTablet ? 162 : 304}
          alt="banner"
          preview={false}
        />
        {isMySelf && (
          <MetaButton
            style={{ letterSpacing: '-1px' }}
            type="primary"
            text={!isMobile ? '編集' : ''}
            className="btn-edit btn-primary-pink"
            icon={isMobile && <EditOutlined />}
            onClick={() => navigate(PATH.PROFILE_EDIT)}
          />
        )}
      </div>

      <div className="container-custom">
        <div className="description-box">
          <div className="action-box">
            <div
              style={{
                marginLeft: !isMySelf ? (!isTablet ? '320px' : '0px') : '0px',
              }}
            >
              <Avatar
                shape="square"
                src={user?.avatar_url ? user?.avatar_url : ''}
              >
                {getFirstLetter(user?.username || '')}
              </Avatar>
            </div>
            {isTablet && (
              <div className={`${isMySelf && 'button-box'}`}>
                <Typography.Title level={2}>{user?.username}</Typography.Title>
              </div>
            )}

            <div className="button-box">
              <MetaButton
                type={isFollow ? 'primary' : 'normal'}
                text={isFollow ? 'フォロー済み' : 'フォローする'}
                onClick={() => handleFollowed(isFollow)}
                style={{ width: '133px' }}
                className="btn-primary-pink"
              />
              <p>
                {user?.total_followers}
                <span style={{ marginLeft: 3 }}>
                  {user?.total_followers > 1 ? 'フォロワー' : 'フォロワー'}
                </span>
              </p>
              <p>
                {user?.total_followings}
                <span style={{ marginLeft: 3 }}>
                  {user?.total_followings > 1 ? 'フォロー中' : 'フォロー中'}
                </span>
              </p>
            </div>
          </div>

          {isMySelf && (
            <Typography.Title level={2}>{user?.username}</Typography.Title>
          )}

          {isMySelf && (
            <Space size={[32, 4]} wrap>
              <p
              // onClick={() =>
              //   setTypeModal({
              //     open: true,
              //     type: 'Follower',
              //     data: followerLists,
              //   })
              // }
              >
                {user?.total_followers}
                <span style={{ marginLeft: 3 }}>
                  {/* {user?.total_followers > 1 ? 'followers' : 'follower'} */}
                  {user?.total_followers > 1 ? 'フォロワー' : 'フォロワー'}
                </span>
              </p>
              <p
              // onClick={() =>
              //   setTypeModal({
              //     open: true,
              //     type: 'Following',
              //     data: followingLists,
              //   })
              // }
              >
                {user?.total_followings}
                <span style={{ marginLeft: 3 }}>
                  {user?.total_followings > 1 ? 'フォロー中' : 'フォロー中'}
                </span>
              </p>
            </Space>
          )}
          {!isMySelf && !isTablet && (
            <Typography.Title level={2}>{user?.username}</Typography.Title>
          )}

          <div className="env-box" style={{ margin: '20px 0px' }}>
            <Space size={[10, 4]} wrap style={{ justifyContent: 'center' }}>
              {user?.labels?.map((t, i) => (
                <Tag
                  key={i}
                  style={{
                    background: t?.color,
                    color: '#fff',
                    border: 'none',
                    height: '24px',
                    paddingTop: '1px',
                  }}
                >
                  {t?.name}
                </Tag>
              ))}
            </Space>
          </div>

          {user?.url && (
            <div className="link-redirect">
              <a href={user?.url} target="_blank" rel="noopener noreferrer">
                {user?.url}
              </a>
            </div>
          )}

          {currentUser && isMySelf && (
            <Space direction="horizontal" className="copy-box">
              <p>配信アドレス</p>

              <div className="copy-input-box">
                <div className="copy-link">
                  <span className="ellipsis-1">
                    {String(
                      `${process.env.REACT_APP_URL}${PATH.STREAM}/${currentUser?.username}`,
                    )}
                  </span>
                </div>

                {isCopy ? (
                  <CheckOutlined className="copy-icon copy-icon-active" />
                ) : (
                  <CopyOutlined
                    className="copy-icon"
                    onClick={() => {
                      setIsCopy(true);
                      navigator.clipboard.writeText(
                        `${process.env.REACT_APP_URL}${PATH.STREAM}/${currentUser?.username}`,
                      );
                    }}
                  />
                )}
              </div>
            </Space>
          )}

          <div className="de-info">
            <div
              style={{ whiteSpace: 'pre-line' }}
              className="ant-typography"
              dangerouslySetInnerHTML={{ __html: user?.bio }}
            />
          </div>
        </div>

        {isMySelf && (
          <div className="video-container">
            <Tabs
              onChange={handleChangeTab}
              activeKey={tabKey}
              items={tabs?.map((t, i) => {
                return {
                  label: (
                    <span key={i}>
                      <Image
                        alt="avatar"
                        preview={false}
                        src={t?.icon}
                        width={20}
                        style={{
                          filter:
                            tabKey === t?.key ? 'brightness(0) invert(1)' : '',
                        }}
                      />
                      {t?.name}
                    </span>
                  ),
                  key: t?.key,
                };
              })}
            />

            {tabKey === '1' && (
              <FollowedStreamer followingLists={followingLists} />
            )}

            {tabKey === '2' && (
              <BalanceLists
                currentUser={currentUser}
                fetchPointActivity={fetchPointActivity}
                activities={activities}
                sumOfActivity={sumOfActivity}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isLoading={false}
                setIsLoading={setIsLoading}
              />
            )}
          </div>
        )}
      </div>

      {/* <FollowModal typeModal={typeModal} setTypeModal={setTypeModal} /> */}
    </Wrapper>
  );
}

export const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  background-color: #231f1f;
  height: ${props => (props.isMySelf ? 'auto' : 'calc(100vh - 60px)')};

  @media only screen and (max-width: 703px) {
    height: ${props => (props.isMySelf ? 'auto' : 'calc(100vh - 100px)')};
  }

  .ant-image,
  .ant-image-mask,
  .ant-image-mask:hover {
    border-radius: 20px;
  }

  .ant-avatar {
    background: #ff347d;
    font-size: 60px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border-radius: 20px;
    margin: auto;
    @media only screen and (max-width: 1024px) {
      width: 120px;
      height: 120px;
    }
  }

  .ant-avatar-image {
    background: transparent;
  }
  @media only screen and (max-width: 1024px) {
    .ant-image,
    .ant-image-img {
      width: 100%;
      object-fit: cover;
    }
  }
  .profile-banner {
    width: 100%;
    position: relative;
    .btn-edit {
      z-index: 1;
      position: absolute;
      bottom: 18px;
      right: 20px;
      width: 132px;
      box-shadow: none;

      @media only screen and (max-width: 703px) {
        width: 32px;
        .anticon {
          font-size: 18px;
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      overflow: hidden;
    }

    .ant-image-img {
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }

  .description-box {
    text-align: center;
    margin-top: -90px;
    margin-bottom: 30px;
    @media only screen and (max-width: 1024px) {
      margin-top: -60px;
    }
    .ant-avatar {
      cursor: pointer;
    }

    .ant-typography {
      color: #fff;
    }

    .link-redirect {
      margin-bottom: 20px;
      a {
        color: #eb7d17;
        font-size: 14px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    h2 {
      font-size: 34px;
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      color: #928c8c;
    }
    .button-box {
      display: flex;
      gap: 20px;
      margin: 20px auto;
      justify-content: center;
    }
    .de-info {
      background-color: #494443;
      padding: 10px;
      border-radius: 7px;
      text-align: left;
      min-height: 120px;
      .ant-typography {
        max-height: 120px;
        overflow-y: scroll;
        margin-bottom: 0px;
        color: #928c8c;
        ::-webkit-scrollbar-track {
          background-color: #f3f4f6;
        }
        ::-webkit-scrollbar {
          width: 2px;
          background-color: #d9d9d9;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #d9d9d9;
          border: 1px solid #d9d9d9;
        }
      }
    }
  }

  .action-box {
    display: flex;
    justify-content: center;
    position: relative;
    gap: 20px;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 703px) {
      width: ${props => (props.isMySelf ? 'max-content' : 'auto')};
      margin: auto;
      gap: 0;
    }
    .button-box {
      width: auto;
      margin: 0px;
      align-items: center;
      margin-top: 75px;
      display: ${props => (props.isMySelf ? 'none' : 'flex')};
      right: 180px;
      bottom: 33px;
      @media only screen and (max-width: 1024px) {
        margin: auto;
      }

      p {
        display: flex;
        align-items: center;
      }
    }
  }

  .video-container {
    text-align: center;
    .suggestion-header {
      position: relative;
      border-bottom: 1px solid #d1d5db;
      display: flex;
      justify-content: center;
      gap: 9px;
      margin-bottom: 20px;
      padding-bottom: 10px;
      h5 {
        font-size: 14px;
        font-weight: 700;
      }
    }
    .sort-list {
      display: flex;
      gap: 12px;
      position: absolute;
      right: 0px;
      .ant-image {
        cursor: pointer;
      }
    }

    .suggestion-box {
      margin-top: 20px;
      text-align: left;
      .ant-avatar {
        width: 100%;
        height: 132px;
        span {
          color: #fff;
          font-size: 60px;
        }
      }
      video {
        cursor: pointer;
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
      h5 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0px;
      }
      span {
        color: #928c8c;
        font-size: 12px;
      }
    }
    @media only screen and (max-width: 1024px) {
      .ant-image,
      .ant-image-img {
        object-fit: fill;
      }
    }
  }

  .ant-tabs {
    width: 100%;
    .ant-tabs-nav-operations,
    .ant-tabs-nav-operations-hidden {
      display: none !important;
    }
    .ant-tabs-nav-operations {
      display: none;
    }
    .ant-tabs-nav {
      border-bottom: 1px solid #5f5858;
      padding-bottom: 10px;
      margin-bottom: 12px;
      overflow: hidden;
    }
    .ant-tabs-nav::before {
      display: none;
    }
    .ant-tabs-tab:not(:first-child) {
      border-left: 1px solid #5f5858;
    }
    .ant-tabs-tab {
      color: #5f5858;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      padding: 0px;
      .ant-tabs-tab-btn:active {
        color: #1f2937;
      }
      .ant-tabs-tab-btn {
        span {
          padding-top: 5px;
        }
      }
      .ant-image {
        margin-right: 7px;
        position: relative;
        top: -2px;
      }
      &:hover {
        color: #fff;
        img {
          filter: brightness(0) invert(1) !important;
        }
      }
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 8px;
    }
    .ant-tabs-nav-list {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #ffffff;
      }
      span {
        color: #ffffff;
        font-weight: 700;
      }
    }
    .ant-tabs-ink-bar-animated {
      display: none;
    }
  }
  .ant-tabs-content-holder {
    display: none;
  }

  .balance-box {
    border: 1px solid #ff347d;
    padding: 17px 29px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 8px;
    gap: 18px;
    @media only screen and (max-width: 703px) {
      flex-direction: column;
      padding: 20px;
    }
    .balance-text {
      display: flex;
      align-items: flex-end;
      gap: 20px;
      line-height: 1;
      @media only screen and (max-width: 703px) {
        align-items: flex-start;
        justify-content: space-between;
      }
      span:first-child {
        color: #fff;
        font-size: 44px;
        font-weight: 300;
        @media only screen and (max-width: 640px) {
          font-size: 32px;
        }
      }

      > div {
        display: flex;
        gap: 20px;
        align-items: flex-end;
        @media only screen and (max-width: 703px) {
          flex-direction: column;
          gap: 7px;
        }
        span:first-child {
          color: #ff347d;
          font-size: 44px;
          font-weight: 700;
          @media only screen and (max-width: 640px) {
            font-size: 32px;
          }
        }
      }
    }
    .btn-edit {
      height: 44px;
      width: 338px;
      font-size: 18px;
      @media only screen and (max-width: 703px) {
        width: 100%;
      }
    }

    .balance-btn-Purchase {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media only screen and (max-width: 703px) {
        justify-content: center;
        align-items: center;
      }
      p {
        margin-top: 10px;
        color: #928c8c;
        font-size: 14px;
      }
    }
  }

  .copy-box {
    margin-bottom: 20px;
    justify-content: center;
    min-height: 44px;
    height: 44px;
    width: 100%;
    .ant-space-item {
      height: 44px;
      align-items: center;
      display: flex;
      @media only screen and (max-width: 640px) {
        width: 100%;
        height: max-content;
      }
    }
    @media only screen and (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
      height: max-content;
    }
    .copy-input-box {
      display: grid;
      grid-template-columns: 1fr 44px;
      gap: 8px;
      width: 100%;
    }
    .copy-link {
      border-radius: 10px;
      min-height: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      height: max-content;
      box-shadow: 0px 1px 2px 0px #1018280d;
      color: #928c8c;
      text-decoration: underline;
      font-size: 14px;
      padding: 10px 22px;
      outline: none;
      margin: 0px;
      background: #494443;
      border: 1px solid #5f5858;
      max-width: 500px;
      text-underline-offset: 3px;
      width: 100%;
      white-space: nowrap;
      overflow: auto;
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      @media only screen and (max-width: 640px) {
        max-width: none;
      }
      span {
        text-align: left;
      }
    }
    .copy-icon {
      background: #d2cfce;
      padding: 10px;
      border-radius: 7px;
      color: #928c8c;
      width: 44px;
      height: 100%;
      transition: 0.3s;
      justify-content: center;
      font-size: 18px;
      &:hover {
        background: #ff347d;
        color: #fff;
        transition: 0.3s;
      }
    }
    .copy-icon-active {
      background: #fff;
      color: green;
      transition: 0.3s;
      border: 1px solid #d1d5db;
      &:hover {
        background: #fff;
        color: green;
        transition: 0.3s;
        border: 1px solid #d1d5db;
      }
    }
  }
`;
