import { Image, Typography, notification } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getUserRoom } from 'app/apis/auth';
import { MetaLoading } from 'app/components';
import { ModalStreamList } from 'app/components/Modals/StreamList';
import DynamicRtc from 'app/components/BaseRTC';
import ChatRTCViewer from 'app/components/ChatRTCViewer';
import { MetaHelmet } from 'app/components/MetaHelmet';
import { PATH } from 'app/constants/path';
import ChatGroup from 'app/pages/StreamPage/components/ChatGroup';
import LiveScreen from 'app/pages/StreamPage/components/LiveScreen';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { handlePageUrl } from 'utils/common';
import { breakpoints } from 'utils/media-breakpoint';
import { getUrlParam } from 'utils/utils';
import useInit from '../hooks/useInit';
import GetPoint from 'app/pages/Point/GetPoint';
import {
  CustomMetaButton,
  ErrorClientBannedModalContainer,
  ErrorIconContainer,
  FooterCustom,
  ModalContainer,
  Wrapper,
  ModalAlarmLineContainer,
} from './viewer-styles';
import { ErrorIcon } from 'styles/image';
import { useMqtt } from 'app/hooks';
import { AlarmLine } from 'styles/image';

export const LiveStreamViewerPage = memo(() => {
  const routeId = useParams();

  const navigate = useNavigate();

  const [roomIDCurrent, setRoomIDCurrent] = useState<any>(null);

  const [roomNameCurrent, setRoomNameCurrent] = useState<any>('');

  const [mqttRoomNameCurrent, setMqttRoomNameCurrent] = useState<string | null>(
    null,
  );

  const [isMultipleRoomsError, setIsMultipleRoomsError] = useState<any>(false);

  const [isClientBannedError, setIsClientBannedError] = useState(false);

  const [isShowModalStreamList, setIsShowModalStreamList] = useState(false);

  const [isStreamEnded, setIsStreamEnded] = useState(false);

  const {
    handleJoin,
    handleLeave,
    handleLocalChange,
    handleRemoteChange,
    isMySelf,
    setState,
    addUser,
    addStream,
    updateStream,
    updateStreamConfig,
    removeStream,
    removeUser,
    userStream,
    setUserStream,
    actions,
    audience,
    anchor,
    dispatch,
    id,
    video,
    audio,
    mode,
    useStringRoomID,
    setUseStringRoomID,
    sendButton,
    roomID,
    cameraID,
    microphoneID,
    isLoading,
    setIsLoading,
    messageLists,
    setMessageLists,
    inputMessage,
    setInputMessage,
    isTheater,
    isJoined,
    isPublished,
    RTC,
    setRTC,
    localStreamConfig,
    remoteStreamConfigList,
    currentUser,
    actionProfile,
    currentPerson,
    streamConfig,
    receiveStatus,
    setReceiveStatus,
    full,
    setFull,
    roomLists,
    totalOfLike,
    totalOfViewer,
    handleLike,
    liked,
    stopRoom,
    setIdVideo,
    isShowScreenLive,
    setIsShowScreenLive,
    handleLeaveById,
    isMobileLive,
    chatRTC,
    defaultMedia,
    idVideo,
    fetchRoomLinkError,
    requirePoint,
    isShowModalSubmitViewLive,
    handleCancelModalViewLive,
    submitPointViewLive,
    showPointPopup,
    setShowPointPopup,
    isMuteVideo,
    isUserAllowViewLive,
  } = useInit();

  useMqtt({
    topicName: `user/current_room/${currentUser?.id}`,
    callback: onGetUserInfor,
  });

  function onGetUserInfor(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const { data } = msg;

      const dataParse = JSON.parse(data);

      const roomName = dataParse.room_name;

      if (roomName !== null) {
        setMqttRoomNameCurrent(dataParse.room_name);
        return;
      }
    } catch (error) {
      // toast.error('配信情報が取得できません');
    }
  }

  const [api, contextHolder] = notification.useNotification();

  const openNotification = message => {
    if (!message || isClientBannedError || isMultipleRoomsError) {
      return;
    } else {
      return api.info({
        key: 'liveEnded',
        message,
      });
    }
  };

  const getUserRoomAPI = async () => {
    try {
      const res: any = await getUserRoom();

      if (res?.data?.room_anchor) {
        dispatch(
          actionsToast.openToast({
            message:
              'You are live streaming so you cannot watch the live stream in another room.',
            type: 'error',
          }),
        );
        setIsShowScreenLive(false);
        return;
      }

      setRoomIDCurrent(res?.data?.room_id);

      setRoomNameCurrent(res?.data?.room_name || '');
    } catch (error) {
      setRoomIDCurrent(null);
      setRoomNameCurrent('');
    }
  };

  const handleCheckRoom = () => {
    if (roomNameCurrent && routeId?.id !== roomNameCurrent) {
      setIsMultipleRoomsError(true);

      setIsShowScreenLive(false);
    } else {
      setIsShowScreenLive(true);
    }
  };

  const handleWatchOldLive = () => {
    navigate(`${PATH.STREAM}/${roomNameCurrent}`);

    navigate(0);
  };

  const handleWatchNewLive = () => {
    handleLeaveById(roomIDCurrent.toString());

    // setRoomNameCurrent(routeId.id);

    setIsMultipleRoomsError(false);
  };

  useEffect(() => {
    getUserRoomAPI();
    handlePageUrl();
    setUseStringRoomID(getUrlParam('useStringRoomID') === 'true');
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleCheckRoom();
  }, [roomIDCurrent, roomNameCurrent]);

  useEffect(() => {
    const fetchPerson = async id => {
      if (id && roomLists?.rows?.length > 0) {
        const personID = roomLists?.rows?.find(ele => ele?.id == id)?.owner_id;

        if (personID) {
          dispatch(actionProfile.actions.getPerson({ id: personID }));
        }
      }
    };

    fetchPerson(roomID);
  }, [roomLists, roomID]);

  useEffect(() => {
    dispatch(
      actions.setStreamConfig({
        userID: userStream?.id,
        cameraID,
        microphoneID,
        roomID,
      }),
    );
  }, [cameraID, microphoneID, roomID]);

  useEffect(() => {
    setUserStream(currentPerson);
  }, [currentPerson]);

  useEffect(() => {
    if (streamConfig) {
      const { cameraID, microphoneID, roomID } = streamConfig;
      if (cameraID && microphoneID && roomID) {
        handleJoin();
      }
    }
  }, [streamConfig]);

  useEffect(() => {
    if (isClientBannedError || isMultipleRoomsError) {
      setIsShowModalStreamList(false);
    }

    if (
      isStreamEnded &&
      !isClientBannedError &&
      !isMultipleRoomsError &&
      (mqttRoomNameCurrent === null || mqttRoomNameCurrent === routeId.id)
    ) {
      openNotification('配信が終了しました');
    }
  }, [
    isStreamEnded,
    isClientBannedError,
    isMultipleRoomsError,
    roomNameCurrent,
    mqttRoomNameCurrent,
  ]);

  const hasReloaded = sessionStorage.getItem('hasReloaded');
  React.useEffect(() => {
    if (!window.location.pathname?.includes('/stream/')) {
      sessionStorage.setItem('hasReloaded', 'true');
    } else {
      if (hasReloaded) {
        sessionStorage.removeItem('hasReloaded');
        window.location.reload();
      }
    }
  }, [hasReloaded]);

  return (
    <Wrapper
      isTheater={isTheater}
      isMySelf={isMySelf}
      breakpoints={breakpoints}
    >
      <MetaHelmet />
      <MetaLoading loading={isLoading} />
      {userStream && (
        <>
          {/* Components  */}
          <LiveScreen
            RTC={RTC}
            isTheater={isTheater}
            remoteStreamConfigList={remoteStreamConfigList}
            localStreamConfig={localStreamConfig}
            handleLocalChange={handleLocalChange}
            handleRemoteChange={handleRemoteChange}
            userStream={userStream}
            currentUser={currentUser}
            handleJoin={handleJoin}
            handleLeave={handleLeave}
            isMySelf={isMySelf}
            full={full}
            setFull={setFull}
            totalOfLike={totalOfLike}
            totalOfViewer={totalOfViewer}
            liked={liked}
            handleLike={handleLike}
            setIdVideo={setIdVideo}
            roomID={roomID}
            messageLists={messageLists}
            dispatch={dispatch}
            sendButton={sendButton}
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            isDisabled={remoteStreamConfigList?.length === 0}
            setMessageLists={setMessageLists}
            groupID={roomID}
            setReceiveStatus={setReceiveStatus}
            isClientBannedError={isClientBannedError}
            isMultipleRoomsError={isMultipleRoomsError}
            isMobileLive={isMobileLive}
            idVideo={idVideo}
            isMuteVideo={isMuteVideo}
          />
          <ChatGroup
            isTheater={isTheater}
            chatRTC={chatRTC}
            userStream={userStream}
            currentUser={currentUser}
            messageLists={messageLists}
            setMessageLists={setMessageLists}
            setInputMessage={setInputMessage}
            handleLocalChange={handleLocalChange}
            sendButton={sendButton}
            inputMessage={inputMessage}
            localStreamConfig={localStreamConfig}
            handleJoin={handleJoin}
            handleLeave={handleLeave}
            isMySelf={isMySelf}
            receiveStatus={receiveStatus}
            setReceiveStatus={setReceiveStatus}
            dispatch={dispatch}
            roomID={roomID}
            isDisabled={remoteStreamConfigList?.length === 0}
          />

          {/* Tools  */}

          <div style={{ display: 'none' }}>
            {isShowScreenLive && currentUser && isUserAllowViewLive && (
              <DynamicRtc
                onRef={ref => setRTC(ref)}
                isMySelf={isMySelf}
                userStream={userStream}
                userID={`${currentUser?.id}`}
                userSig={`${currentUser?.user_sign}`}
                roomID={roomID}
                useStringRoomID={useStringRoomID}
                cameraID={cameraID}
                microphoneID={microphoneID}
                audio={audio}
                video={video}
                mode={mode}
                role={isMySelf ? anchor : audience}
                isJoined={isJoined}
                defaultMedia={defaultMedia}
                isPublished={isPublished}
                setState={setState}
                addUser={addUser}
                removeUser={removeUser}
                addStream={addStream}
                updateStream={updateStream}
                updateStreamConfig={updateStreamConfig}
                removeStream={removeStream}
                stopRoom={stopRoom}
                setIsClientBannedError={setIsClientBannedError}
                setIsStreamEnded={setIsStreamEnded}
              />
            )}

            {currentUser && remoteStreamConfigList?.length > 0 && (
              <ChatRTCViewer
                localStreamConfig={localStreamConfig}
                currentUser={currentUser}
                groupID={roomID}
                setMessageLists={setMessageLists}
                inputMessage={inputMessage}
                sendButton={sendButton}
                setReceiveStatus={setReceiveStatus}
              />
            )}
          </div>
        </>
      )}

      {contextHolder}

      {/* Modal when join multiple room */}
      <ModalContainer
        open={isMultipleRoomsError}
        title=""
        centered
        wrapClassName="modal-rotate-screen-wrapper"
        footer={
          <FooterCustom>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '120px 120px',
                gap: '20px',
              }}
            >
              <CustomMetaButton
                type="primary"
                onClick={handleWatchOldLive}
                text="Yes"
                style={{ width: '122px' }}
              />
              <CustomMetaButton
                className="btn-cancel"
                type="default"
                onClick={handleWatchNewLive}
                text="No"
                style={{
                  width: '122px',
                }}
              />
            </div>
          </FooterCustom>
        }
      >
        <p className="modal-title">View livestream</p>
        <Typography.Title
          level={5}
          style={{ textAlign: 'center', marginTop: '8px' }}
        >
          {`${roomNameCurrent}を視聴中ですが、戻りますか？`}
        </Typography.Title>
      </ModalContainer>

      {/* Modal when join one room in multiple tabs  */}
      <ErrorClientBannedModalContainer
        open={isClientBannedError}
        title=""
        centered
        wrapClassName="modal-rotate-screen-wrapper"
        footer={null}
      >
        <ErrorIconContainer>
          <Image
            width={36}
            height={36}
            src={ErrorIcon}
            alt="error-icon"
            preview={false}
          />
        </ErrorIconContainer>

        <p className="modal-title">View livestream</p>

        <Typography.Title
          level={5}
          style={{ textAlign: 'center', marginTop: '8px' }}
        >
          You can not use in multiple window. Please close this window.
        </Typography.Title>
      </ErrorClientBannedModalContainer>

      {/* Modal when live viewers run out of free time */}
      {!fetchRoomLinkError && isShowScreenLive && requirePoint !== 0 && (
        <ModalAlarmLineContainer
          open={isShowModalSubmitViewLive}
          title=""
          centered
          wrapClassName="modal-rotate-screen-wrapper"
          footer={
            <FooterCustom>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '120px 120px',
                  gap: '20px',
                }}
              >
                <CustomMetaButton
                  className="btn-cancel"
                  type="default"
                  onClick={() => handleCancelModalViewLive()}
                  text="退出 "
                  style={{
                    width: '122px',
                  }}
                />
                <CustomMetaButton
                  type="primary"
                  onClick={submitPointViewLive}
                  text="継続 "
                  style={{ width: '122px' }}
                />
              </div>
            </FooterCustom>
          }
        >
          <div className="popup-header">
            <img src={AlarmLine} alt="" />
          </div>
          <p className="modal-title">無料視聴時間が終了しました。</p>
          <p className="modal-content">
            視聴を継続するには<span>{requirePoint}</span>ポイントが必要です。
          </p>
        </ModalAlarmLineContainer>
      )}

      {showPointPopup && <GetPoint setShowPointPopup={setShowPointPopup} />}

      {isShowModalStreamList && (
        <ModalStreamList isShowModalStreamList={isShowModalStreamList} />
      )}
    </Wrapper>
  );
});
