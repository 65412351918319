import { Slider, Typography } from 'antd';
import { MetaLoading } from 'app/components';
import DynamicRtc from 'app/components/BaseRTC';
import ChatRTC from 'app/components/ChatRTC';
import DeviceSelect from 'app/components/DeviceSelect';
import { MetaHelmet } from 'app/components/MetaHelmet';
import UserIDInput from 'app/components/UserIDInput';
import UserList from 'app/components/UserList';
import ChatGroup from 'app/pages/StreamPage/components/ChatGroup';
import LiveScreen from 'app/pages/StreamPage/components/LiveScreen';
import React, { memo, useEffect, useState } from 'react';
import { breakpoints } from 'utils/media-breakpoint';
import useInit from '../hooks/useInit';
import { LivestreamContainer, Wrapper } from './styles';
import { useMqtt } from 'app/hooks';
import mqtt, { MqttClient } from 'mqtt';

export const LiveStreamPage = memo(() => {
  const {
    changeBeauty,
    handleJoin,
    handleLeave,
    handleLocalChange,
    handlePublish,
    handleRemoteChange,
    handleUnPublish,
    isMySelf,
    setState,
    addUser,
    addStream,
    updateStream,
    updateStreamConfig,
    removeStream,
    removeUser,
    userStream,
    setUserStream,
    actions,
    audience,
    anchor,
    dispatch,
    video,
    audio,
    mode,
    useStringRoomID,
    setShareRTC,
    sendButton,
    userID,
    setUserID,
    roomID,
    cameraID,
    setCameraID,
    microphoneID,
    setMicrophoneID,
    isLoading,
    messageLists,
    setMessageLists,
    inputMessage,
    setInputMessage,
    isTheater,
    isJoined,
    isPublished,
    RTC,
    setRTC,
    localStreamConfig,
    remoteStreamConfigList,
    currentUser,
    receiveStatus,
    setReceiveStatus,
    full,
    setFull,
    totalOfLike,
    totalOfViewer,
    handleLike,
    liked,
    setIdVideo,
    stopRoom,
    chatRTC,
    setChatRTC,
    setDefaultMedia,
    defaultMedia,
    startRoom,
    idVideo,
  } = useInit();

  // const PageHandleDevice = () => {
  //   return (
  //     <div
  //       style={{
  //         padding: '30px 50px',
  //         display: 'none',
  //       }}
  //     >
  //       <div
  //         style={{
  //           display: 'grid',
  //           gridTemplateColumns: '1fr 1fr',
  //           gap: '20px',
  //         }}
  //       >
  //         <UserIDInput
  //           disabled={isJoined}
  //           onChange={value => setUserID(`${currentUser?.id}`)}
  //           defaultValue={''}
  //         ></UserIDInput>

  //         <DeviceSelect
  //           name="camera"
  //           deviceType="camera"
  //           placeHolder="Camera"
  //           onChange={value => setCameraID(value)}
  //         ></DeviceSelect>

  //         <DeviceSelect
  //           name="microphone"
  //           deviceType="microphone"
  //           placeHolder="Microphone"
  //           onChange={value => setMicrophoneID(value)}
  //         ></DeviceSelect>

  //         <UserList
  //           localStreamConfig={localStreamConfig}
  //           remoteStreamConfigList={remoteStreamConfigList}
  //         ></UserList>
  //       </div>
  //     </div>
  //   );
  // };

  useEffect(() => {
    dispatch(
      actions.setStreamConfig({
        userID: userStream?.id,
        cameraID,
        microphoneID,
        roomID,
      }),
    );
  }, [userID, cameraID, microphoneID, roomID]);

  useEffect(() => {
    if (currentUser) {
      setUserStream(currentUser);
    }
  }, [currentUser, isMySelf]);

  const [image, setImage] = useState(null);

  // useMqtt({
  //   isRun: (image && roomID) || false,
  //   topicName: 'streaming',
  //   dependencies: [image],
  //   type: 'publish',
  //   message: `roomID=${roomID}&userid=${currentUser?.id}&usersig=${currentUser?.user_sign}&file=params[:${image}]`,
  // });

  useEffect(() => {
    if (image && roomID) {
      const client: MqttClient = mqtt.connect(
        String(process.env.REACT_APP_MQTT_URL),
        {
          username: String(process.env.REACT_APP_MQTT_USERNAME),
          password: String(process.env.REACT_APP_MQTT_PASSWORD),
        },
      );
      console.log(image);

      client.publish(
        'streaming',
        `roomID=${roomID}&userid=${currentUser?.id}&usersig=${currentUser?.user_sign}&file=params[:${image}]`,
        {
          properties: { contentType: 'json' },
        },
      );
    }
  }, [image]);

  return (
    <Wrapper isTheater={isTheater} isMySelf={isMySelf}>
      <MetaHelmet />

      <MetaLoading loading={isLoading} />

      {userStream && (
        <>
          {/* Components  */}
          <LivestreamContainer breakpoints={breakpoints}>
            <LiveScreen
              RTC={RTC}
              chatRTC={chatRTC}
              isTheater={isTheater}
              remoteStreamConfigList={remoteStreamConfigList}
              localStreamConfig={localStreamConfig}
              handleLocalChange={handleLocalChange}
              handleRemoteChange={handleRemoteChange}
              userStream={userStream}
              currentUser={currentUser}
              handleJoin={handleJoin}
              handleLeave={handleLeave}
              isMySelf={isMySelf}
              full={full}
              setFull={setFull}
              totalOfLike={totalOfLike}
              totalOfViewer={totalOfViewer}
              handleLike={handleLike}
              liked={liked}
              setIdVideo={setIdVideo}
              roomID={roomID}
              messageLists={messageLists}
              dispatch={dispatch}
              sendButton={sendButton}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              isDisabled={!localStreamConfig}
              setMessageLists={setMessageLists}
              groupID={roomID}
              setReceiveStatus={setReceiveStatus}
              setDefaultMedia={setDefaultMedia}
              setImage={setImage}
              idVideo={idVideo}
            />

            <ChatGroup
              chatRTC={chatRTC}
              isTheater={isTheater}
              userStream={userStream}
              currentUser={currentUser}
              messageLists={messageLists}
              setMessageLists={setMessageLists}
              setInputMessage={setInputMessage}
              handleLocalChange={handleLocalChange}
              sendButton={sendButton}
              inputMessage={inputMessage}
              localStreamConfig={localStreamConfig}
              handleJoin={handleJoin}
              handleLeave={handleLeave}
              isMySelf={isMySelf}
              receiveStatus={receiveStatus}
              setReceiveStatus={setReceiveStatus}
              dispatch={dispatch}
              roomID={roomID}
              isDisabled={!localStreamConfig}
            />
          </LivestreamContainer>

          {/* Tools  */}
          {/* <PageHandleDevice /> */}

          <div style={{ display: 'none' }}>
            <UserList
              localStreamConfig={localStreamConfig}
              remoteStreamConfigList={remoteStreamConfigList}
            />

            <DeviceSelect
              name="microphone"
              deviceType="microphone"
              placeHolder="Microphone"
              onChange={value => setMicrophoneID(value)}
            ></DeviceSelect>
          </div>

          <div>
            {currentUser && userID && (
              <DynamicRtc
                onRef={ref => setRTC(ref)}
                isMySelf={isMySelf}
                userStream={userStream}
                userID={`${currentUser?.id}`}
                userSig={`${currentUser?.user_sign}`}
                roomID={roomID}
                useStringRoomID={useStringRoomID}
                cameraID={cameraID}
                microphoneID={microphoneID}
                defaultMedia={defaultMedia}
                audio={audio}
                video={video}
                mode={mode}
                role={isMySelf ? anchor : audience}
                isJoined={isJoined}
                isPublished={isPublished}
                setState={setState}
                addUser={addUser}
                removeUser={removeUser}
                addStream={addStream}
                updateStream={updateStream}
                updateStreamConfig={updateStreamConfig}
                removeStream={removeStream}
                stopRoom={stopRoom}
                startRoom={startRoom}
              />
            )}

            {currentUser && localStreamConfig?.stream && (
              <ChatRTC
                onRef={ref => setChatRTC(ref)}
                localStreamConfig={localStreamConfig}
                currentUser={currentUser}
                groupID={roomID}
                messageLists={messageLists}
                setMessageLists={setMessageLists}
                inputMessage={inputMessage}
                sendButton={sendButton}
                setReceiveStatus={setReceiveStatus}
              />
            )}
          </div>
        </>
      )}
    </Wrapper>
  );
});
