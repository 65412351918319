import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { authSaga } from './saga';
import { ProfileState } from './types';

export const initialState: ProfileState = {
  currentPerson: null,
  followerLists: [],
  followingLists: [],
  isLoading: false,
  labels: [],
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // get person
    getPerson(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    getPersonSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.currentPerson = action.payload.data;
    },
    getPersonFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // follow
    followed(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    followedSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    followedFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // un-follow
    unFollowed(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    unFollowedSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    unFollowedFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // get following list
    getFollowingList(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    getFollowingListSuccess(state, action: PayloadAction<any>) {
      state.followingLists = action.payload.data;
      state.isLoading = false;
    },
    getFollowingListFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // get follower list
    getFollowerList(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    getFollowerListSuccess(state, action: PayloadAction<any>) {
      state.followerLists = action.payload.data;
      state.isLoading = false;
    },
    getFollowerListFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // get labels
    getLabel(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    getLabelSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.labels = action.payload.data;
    },
    getLabelFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // Update profile
    updateUser(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    updateUserSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    updateUserFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
  },
});

export const { actions: authActions } = slice;

export const useProfileSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};
