export const DATA_PARTNER_SITE = [
  {
    text: '北海道',
    url: 'https://www.fucolle.com/01/',
    alt: '北海道 風俗',
    bgColor: '#2279d4',
  },
  {
    text: '青森',
    url: 'https://www.fucolle.com/02/',
    alt: '青森 風俗',
    bgColor: '#2279d4',
  },
  {
    text: '秋田',
    url: 'https://www.fucolle.com/03/',
    alt: '秋田 風俗',
    bgColor: '#2279d4',
  },
  {
    text: '山形',
    url: 'https://www.fucolle.com/04/',
    alt: '山形 風俗',
    bgColor: '#2279d4',
  },
  {
    text: '岩手',
    url: 'https://www.fucolle.com/05/',
    alt: '岩手 風俗',
    bgColor: '#2279d4',
  },
  {
    text: '宮城',
    url: 'https://www.fucolle.com/06/',
    alt: '宮城 風俗',
    bgColor: '#2279d4',
  },
  {
    text: '福島',
    url: 'https://www.fucolle.com/07/',
    alt: '福島 風俗',
    bgColor: '#2279d4',
  },
  {
    text: '東京',
    url: 'https://www.fucolle.com/08/',
    alt: '東京 風俗',
    bgColor: '#099fc4',
  },
  {
    text: '神奈川',
    url: 'https://www.fucolle.com/09/',
    alt: '神奈川 風俗',
    bgColor: '#099fc4',
  },
  {
    text: '埼玉',
    url: 'https://www.fucolle.com/10/',
    alt: '埼玉 風俗',
    bgColor: '#099fc4',
  },

  {
    text: '千葉',
    url: 'https://www.fucolle.com/11/',
    alt: '千葉 風俗',
    bgColor: '#099fc4',
  },
  {
    text: '栃木',
    url: 'https://www.fucolle.com/12/',
    alt: '栃木 風俗',
    bgColor: '#099fc4',
  },
  {
    text: '茨城',
    url: 'https://www.fucolle.com/13/',
    alt: '茨城 風俗',
    bgColor: '#099fc4',
  },
  {
    text: '群馬',
    url: 'https://www.fucolle.com/14/',
    alt: '群馬 風俗',
    bgColor: '#099fc4',
  },
  {
    text: '愛知',
    url: 'https://www.fucolle.com/15/',
    alt: '愛知 風俗',
    bgColor: '#07995d',
  },
  {
    text: '岐阜',
    url: 'https://www.fucolle.com/16/',
    alt: '岐阜 風俗',
    bgColor: '#07995d',
  },
  {
    text: '静岡',
    url: 'https://www.fucolle.com/17/',
    alt: '静岡 風俗',
    bgColor: '#07995d',
  },
  {
    text: '三重',
    url: 'https://www.fucolle.com/18/',
    alt: '三重 風俗',
    bgColor: '#07995d',
  },
  {
    text: '新潟',
    url: 'https://www.fucolle.com/19/',
    alt: '新潟 風俗',
    bgColor: '#07995d',
  },
  {
    text: '山梨',
    url: 'https://www.fucolle.com/20/',
    alt: '山梨 風俗',
    bgColor: '#07995d',
  },
  {
    text: '長野',
    url: 'https://www.fucolle.com/21/',
    alt: '長野 風俗',
    bgColor: '#07995d',
  },
  {
    text: '石川',
    url: 'https://www.fucolle.com/22/',
    alt: '石川 風俗',
    bgColor: '#07995d',
  },
  {
    text: '富山',
    url: 'https://www.fucolle.com/23/',
    alt: '富山 風俗',
    bgColor: '#07995d',
  },
  {
    text: '福井',
    url: 'https://www.fucolle.com/24/',
    alt: '福井 風俗',
    bgColor: '#07995d',
  },
  {
    text: '大阪',
    url: 'https://www.fucolle.com/25/',
    alt: '大阪 風俗',
    bgColor: '#5a9a05',
  },
  {
    text: '京都',
    url: 'https://www.fucolle.com/27/',
    alt: '京都 風俗',
    bgColor: '#5a9a05',
  },
  {
    text: '兵庫',
    url: 'https://www.fucolle.com/26/',
    alt: '兵庫 風俗',
    bgColor: '#5a9a05',
  },
  {
    text: '滋賀',
    url: 'https://www.fucolle.com/28/',
    alt: '滋賀 風俗',
    bgColor: '#5a9a05',
  },
  {
    text: '奈良',
    url: 'https://www.fucolle.com/29/',
    alt: '奈良 風俗',
    bgColor: '#5a9a05',
  },
  {
    text: '和歌山',
    url: 'https://www.fucolle.com/30/',
    alt: '和歌山 風俗',
    bgColor: '#5a9a05',
  },

  {
    text: '広島',
    url: 'https://www.fucolle.com/32/',
    alt: '広島 風俗',
    bgColor: '#ba9607',
  },
  {
    text: '岡山',
    url: 'https://www.fucolle.com/31/',
    alt: '岡山 風俗',
    bgColor: '#ba9607',
  },
  {
    text: '山口',
    url: 'https://www.fucolle.com/35/',
    alt: '山口 風俗',
    bgColor: '#ba9607',
  },
  {
    text: '鳥取',
    url: 'https://www.fucolle.com/33/',
    alt: '鳥取 風俗',
    bgColor: '#ba9607',
  },
  {
    text: '島根',
    url: 'https://www.fucolle.com/34/',
    alt: '島根 風俗',
    bgColor: '#ba9607',
  },

  {
    text: '香川',
    url: 'https://www.fucolle.com/36/',
    alt: '香川 風俗',
    bgColor: '#ba9607',
  },
  {
    text: '徳島',
    url: 'https://www.fucolle.com/37/',
    alt: '徳島 風俗',
    bgColor: '#ba9607',
  },
  {
    text: '愛媛',
    url: 'https://www.fucolle.com/38/',
    alt: '愛媛 風俗',
    bgColor: '#ba9607',
  },
  {
    text: '高知',
    url: 'https://www.fucolle.com/39/',
    alt: '高知 風俗',
    bgColor: '#ba9607',
  },
  {
    text: '福岡',
    url: 'https://www.fucolle.com/40/',
    alt: '福岡 風俗',
    bgColor: '#d26f05',
  },
  {
    text: '熊本',
    url: 'https://www.fucolle.com/43/',
    alt: '熊本 風俗',
    bgColor: '#d26f05',
  },
  {
    text: '佐賀',
    url: 'https://www.fucolle.com/41/',
    alt: '佐賀 風俗',
    bgColor: '#d26f05',
  },
  {
    text: '長崎',
    url: 'https://www.fucolle.com/42/',
    alt: '長崎 風俗',
    bgColor: '#d26f05',
  },

  {
    text: '大分',
    url: 'https://www.fucolle.com/44/',
    alt: '大分 風俗',
    bgColor: '#d26f05',
  },
  {
    text: '宮崎',
    url: 'https://www.fucolle.com/45/',
    alt: '宮崎 風俗',
    bgColor: '#d26f05',
  },
  {
    text: '鹿児島',
    url: 'https://www.fucolle.com/46/',
    alt: '鹿児島 風俗',
    bgColor: '#d26f05',
  },
  {
    text: '沖縄',
    url: 'https://www.fucolle.com/47/',
    alt: '沖縄 風俗',
    bgColor: '#d26f05',
  },
];
