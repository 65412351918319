/**
 *
 * LoginPage
 *
 */
import { Carousel, Image } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useMediaQuery } from 'react-responsive';
import VerificationInput from 'react-verification-input';

import { MetaTitle } from 'app/components';
import { MetaButton, MetaOtpInput } from 'app/components/Forms';
import { ConfirmIcon, LoginBanner, loginBg } from 'styles/image';
import { MetaHelmet } from 'app/components/MetaHelmet';
import { useAuthSlice } from '../slice';
import { selectAuth } from '../slice/selectors';
import { PATH } from 'app/constants/path';
import { registerVerifyRequest } from 'app/apis/auth';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { STORAGE_KEY } from 'shared/constants/storageKey';

export const RegisterVerification = memo(() => {
  const isMobile = useMediaQuery({ maxWidth: '703px' });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();
  const { isLoading } = useSelector(selectAuth);
  const params = new URLSearchParams(location.search);
  const email = params.get('email');

  const [isResend, setIsResend] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [otp, setOtp] = useState('');

  const onchange = value => {
    setOtp(value);
    setIsDisabled(value?.length !== 6);
  };

  const onToggleSubmit = async () => {
    try {
      if (otp?.length === 6) {
        const res: any = await registerVerifyRequest({ email, otp });
        if (res?.statusCode === 201) {
          dispatch(actions.verifySuccess(res));
          Cookies.set(STORAGE_KEY.ACCESS_TOKEN, res?.data?.accessToken);

          dispatch(
            actionsToast.openToast({
              message:
                'Congratulation! Your account has verified successfully!',
              type: 'verified',
              titleButton: 'Discover Now!',
              urlRedirect: PATH.LIST_STREAM,
            }),
          );
        }
      }
    } catch (err: any) {
      const { code, message } = err.response.data;
      if (code === 6) {
        dispatch(
          actionsToast.openToast({
            message:
              'This verification code is expired. Resend verification code?',
            type: 'confirm',
            imageElement: ConfirmIcon,
            handleFunc: handleResendCode,
          }),
        );
      } else {
        dispatch(
          actionsToast.openToast({
            message: message,
            type: 'error',
          }),
        );
      }
    }
  };

  const handleResendCode = () => {
    setIsResend(true);
    dispatch(actions.resendCode({ email }));
  };

  useEffect(() => {
    if (isResend) {
      setTimeout(() => {
        setIsResend(false);
      }, 600000);
    }
  }, [isResend]);

  useEffect(() => {
    if (!email) {
      navigate(PATH.REGISTER);
    }
  }, [location]);

  return (
    <Wrapper isResend={isResend}>
      <MetaHelmet />
      <Box className="form-content">
        <MetaTitle
          text="Verification!"
          subText={`Please check your email and enter verification code below!\nCode will expire in 10 minutes.`}
        />
        <div className="form-login">
          <div style={{ marginTop: !isMobile ? '40px' : '0px' }}>
            <VerificationInput
              validChars="0-9"
              onChange={onchange}
              placeholder="_"
            />
          </div>

          <MetaButton
            type="primary"
            onClick={onToggleSubmit}
            text="Verify"
            className="btn-primary-pink"
            is_loading={isLoading}
            disabled={isDisabled}
          />
        </div>

        <div className="forgot-pass">
          <p style={{ color: '#FF347D', fontWeight: 700 }}>
            Didn't get the code?
          </p>
          <p style={{ color: '#928C8C' }}>
            Make sure to check your Spam folder.
          </p>
          <p>
            <span style={{ color: '#928C8C' }}>Or</span>{' '}
            <a className="resend-code-cta" onClick={handleResendCode}>
              Click here to resend
            </a>
            <span style={{ color: '#928C8C' }}>.</span>{' '}
          </p>
        </div>
      </Box>
      <CarouseBox>
        <Carousel autoplay effect="fade" speed={2000} infinite>
          <Image
            src={LoginBanner}
            alt="login_banner"
            className="banner_img"
            preview={false}
          />
        </Carousel>
      </CarouseBox>
    </Wrapper>
  );
});

// css
const Wrapper = styled.div<any>`
  overflow: hidden;
  width: 100vw;
  height: 100dvh;
  display: grid;
  grid-template-columns: 37% calc(100% - 37%);
  justify-content: space-between;

  background-image: url('${loginBg}');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;

  @media only screen and (max-width: 1440px) {
    grid-template-columns: 450px calc(100% - 450px);
  }
  @media only screen and (max-width: 703px) {
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    overflow-x: hidden;
  }
  .ant-image {
    width: 100%;
    height: 100%;
    outline: none;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .form-content {
    max-width: 365px;
    width: 100%;
    margin: auto;
    justify-content: flex-start;
    margin-top: 200px;
    height: max-content;
    @media only screen and (max-width: 1440px) {
      margin: auto;
    }
    @media only screen and (max-width: 703px) {
      max-width: 500px;
      padding: 31px;
      border-radius: 20px;
      margin-top: 0px;
      h1 {
        line-height: 1.2;
        margin-bottom: 10px;
      }
      h4 {
        line-height: 1.4;
      }
    }
  }

  .form-login {
    width: 100%;
    max-width: 328px;
    @media only screen and (max-width: 703px) {
      margin-top: 8px;
    }
  }

  .ant-btn-primary {
    margin-top: 20px;
    width: 100%;
    height: 52px;
    font-size: 18px;
  }
  .ant-form-item-explain-error {
    width: fit-content;
  }

  .forgot-pass {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #928c8c;
    cursor: ${({ isResend }: any) => (!isResend ? 'pointer' : 'auto')};
    a {
      color: ${({ isResend }: any) => (!isResend ? '#EB7D17' : '#d8d8d8')};
      font-size: 14px;
      text-decoration: underline;
      &:hover {
        opacity: ${({ isResend }: any) => (!isResend ? '0.8' : '1')};
      }
    }
  }

  .vi__container {
    width: 100%;
    gap: 20px;

    .vi__character {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 10px;
      border: 1px solid #5f5858;
      background-color: #494443;
      font-size: 14px;
      color: #fff;
    }

    .vi__character--selected {
      border: 1px solid #ff347d;
      outline: none;
      color: #fff;
    }

    .vi__character--inactive {
      color: #5f5858;
    }
  }

  .banner_img {
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;

    @media only screen and (max-width: 703px) {
      border-radius: 0;
    }
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .resend-code-cta {
    color: #ff347d !important;
  }
`;

const CarouseBox = styled.div`
  @media only screen and (max-width: 1024px) {
    .ant-image {
      img {
        object-fit: cover;
      }
    }
  }
  @media only screen and (max-width: 703px) {
    height: 280px;
    width: 100%;
  }

  div {
    height: 100% !important;
  }
  .ant-carousel {
    height: 100dvh !important;

    @media only screen and (max-width: 703px) {
      height: 100% !important;
    }
  }
  .ant-carousel:focus-visible {
    outline: none;
  }
  .ant-carousel,
  .ant-carousel .slick-slider,
  .slick-list,
  .slick-track {
    height: 100dvh;
  }
  .slick-dots {
    align-items: center;
    li {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-inline: 10px;
      button {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    li.slick-active {
      width: 18px;
      height: 18px;
    }
  }
`;
