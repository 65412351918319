import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Image,
  Input,
  Menu,
  Pagination,
  Space,
  Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import { getAllUserStreamingRequest } from 'app/apis/profile';
import { MetaLoading } from 'app/components';
import { MetaHelmet } from 'app/components/MetaHelmet';
import { PATH } from 'app/constants/path';
import { useMqtt } from 'app/hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import {
  EyeView,
  LiveIcon,
  NoData,
  BannerAdvertisement,
  BannerAdvertisementMobile,
} from 'styles/image';
import { getFirstLetter } from 'utils/helpers';
import { breakpoints } from 'utils/media-breakpoint';
import { selectAuth } from '../AuthPage/slice/selectors';
import { _log } from 'utils/_log';

import { SteamListUser } from './styles';

export const SORT = {
  DIRECTION: {
    ASC: 'asc',
    DESC: 'desc',
  },
  BY: {
    FOLLOWER: 'total_followers',
    TIME: 'start_time',
    VIEWER: 'current_viewer',
    LIKE: 'current_like',
  },
};

export function HomePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [streamLists, setStreamLists] = useState<any>([]);
  const [streamTotal, setStreamTotal] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const isTablet = useMediaQuery({ maxWidth: '1024px' });
  const isMobile = useMediaQuery({ maxWidth: '703px' });
  const PAGE_SIZE = isMobile ? 8 : isTablet ? 15 : 21;
  const { currentUser } = useSelector(selectAuth);
  const navigate = useNavigate();

  const [values, setValues] = useState({
    page: currentPage,
    limit: PAGE_SIZE,
    search: '',
    order_by: '',
    order_direction: SORT.DIRECTION.DESC,
    onlyFollower: false,
  });

  useMqtt({
    topicName: `room/info/#`,
    dependencies: [values],
    callback: onReceiveStreamList,
  });

  useMqtt({
    topicName: `room/status/#`,
    dependencies: [values],
    callback: onReceiveStreamList,
  });

  const onChange: any = e => {
    const { value } = e.target;
    setValues({ ...values, search: value });
  };

  const onChangePage = (page, _pageSize) => {
    setIsLoading(true);
    setCurrentPage(page);
    fetchStreamList({ ...values, page: page });
  };

  const onPressEnter = e => {
    setIsLoading(true);
    fetchStreamList(values);
  };

  const fetchStreamList = values => {
    getAllUserStreamingRequest(values)
      .then(res => {
        if (res) {
          const { data } = res;
          setStreamLists(data?.rows);
          setStreamTotal(data?.count);
          setIsLoading(false);
        }
      })
      .catch(err => {
        setIsLoading(false);
        _log('fetchStreamList error: ', err, 'error');
      });
  };

  const MenuOption = ({ data }) => {
    return (
      <Menu className="filter-dropdown-menu">
        {data.map(item => (
          <Menu.Item key={item.key}>
            <Button
              type="text"
              className="btn-text"
              onClick={e => {
                setIsLoading(true);
                setValues({
                  ...values,
                  order_by: item.value,
                });
                fetchStreamList({ ...values, order_by: item.value });
              }}
            >
              {item.label}
            </Button>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const onStreamRedirect = item => {
    if (item.owner_id === currentUser.id) {
      toast.error('アクセスできません');
    } else {
      if (isMobile) {
        navigate(`${PATH.STREAM}/${item.owner.username}`);
        navigate(0);
      } else {
        window.open(`${PATH.STREAM}/${item.owner.username}`);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchStreamList(values);
  }, [values]);

  function onReceiveStreamList(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      fetchStreamList(values);
    } catch (error) {
      toast.error('配信のリストが取得できません');
    }
  }

  return (
    <div className="container">
      <MetaHelmet />
      <MetaLoading loading={isLoading} />
      <SteamListUser
        isShowPagination={streamTotal > PAGE_SIZE}
        breakpoints={breakpoints}
      >
        <div className="list-stream-title">
          <p className="text">スパチャLive配信中アーティスト一覧</p>
        </div>
        <div className="search-container">
          <Input
            placeholder="検索する"
            onChange={onChange}
            maxLength={20}
            onPressEnter={onPressEnter}
            suffix={<SearchOutlined />}
          />

          <div className="search-content">
            <span style={{ color: '#9CA3AF' }}>フィルター: </span>
            <Dropdown
              overlay={
                <MenuOption
                  data={[
                    { key: '0', label: '全て', value: '' },
                    {
                      key: '1',
                      label: '視聴者の多い順',
                      value: SORT.BY.VIEWER,
                    },
                    {
                      key: '2',
                      label: 'フォロワーの多い順',
                      value: SORT.BY.FOLLOWER,
                    },
                  ]}
                />
              }
              trigger={['click']}
            >
              <Space
                style={{
                  color: '#FFF',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                }}
              >
                {values.order_by === SORT.BY.FOLLOWER
                  ? 'フォロワーの多い順'
                  : values.order_by === SORT.BY.VIEWER
                  ? '視聴者の多い順'
                  : '全て'}
                <DownOutlined
                  className="f10"
                  style={{
                    paddingBottom: '4px',
                  }}
                />
              </Space>
            </Dropdown>
          </div>
        </div>

        {streamLists?.length > 0 ? (
          <div className="user-stream-list">
            {streamLists?.map((s, i) => (
              <div
                key={i}
                className="item-user"
                onClick={() => onStreamRedirect(s)}
              >
                <div style={{ position: 'relative' }}>
                  <Avatar
                    shape="square"
                    style={{
                      width: '100%',
                      height: isMobile ? '203px' : '220px',
                    }}
                    src={s?.owner?.avatar_url}
                  >
                    {getFirstLetter(s?.owner?.username || '')}
                  </Avatar>

                  <div className="live-text">
                    <span className="dot" />
                    <div className="live-icon">
                      <Image src={LiveIcon} alt="live-icon" preview={false} />
                    </div>
                  </div>
                  <div className="view-info">
                    <p>
                      <Image
                        src={EyeView}
                        alt="heart-icon"
                        preview={false}
                        style={{ marginTop: '-5px' }}
                      />
                      {s?.current_viewer || 0}
                    </p>
                  </div>
                </div>
                <Typography.Title level={5} className="user-name ellipsis-1">
                  {s?.owner?.username}
                </Typography.Title>
                <Typography.Text className="follower">
                  {s?.owner?.total_followers || 0}
                  <span style={{ marginLeft: 3 }}>
                    {/* {s?.owner?.total_followers > 1 ? 'Followers' : 'Follower'} */}
                    {s?.owner?.total_followers > 1
                      ? 'フォロワー'
                      : 'フォロワー'}
                  </span>
                </Typography.Text>
              </div>
            ))}

            {streamTotal > PAGE_SIZE && (
              <div className="pagination">
                <Col xs={24} sm={24}>
                  <Pagination
                    defaultCurrent={1}
                    showSizeChanger={false}
                    current={currentPage}
                    total={streamTotal}
                    pageSize={PAGE_SIZE}
                    onChange={onChangePage}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                  />
                </Col>
              </div>
            )}
          </div>
        ) : (
          <div className="nodata">
            <Image
              width={335}
              height={247}
              preview={false}
              src={NoData}
              style={{
                objectFit: 'cover',
              }}
              alt=""
            />

            <p
              style={{
                color: '#D1D5DB',
                fontSize: '24px',
                fontWeight: 'bold',
                marginTop: '15px',
              }}
            >
              Don’t have any livestream now!
            </p>
          </div>
        )}
      </SteamListUser>
    </div>
  );
}
