import React from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  title?: string;
  description?: string;
}

export const MetaHelmet = ({ title, description }: Props) => {
  return (
    <Helmet>
      <title>
        セクシー生配信「ふーちゃ」｜AV嬢や風俗嬢やメンエス嬢などセクシーな女の子による生配信サイト
      </title>
      <meta property="og:image" content="/fu-cha-logo-16x16.png" />
      <meta
        name="keywords"
        content="LIVE配信,生配信,ふーちゃ,FU-CHA,風俗嬢,メンエス嬢,AV嬢,セラピスト,生中継,LIVEチャット,無料配信,ライバー,ライバー募集"
      />
      <meta
        name="description"
        content="AV嬢、風俗嬢、メンエス嬢などセクシーな女の子による生配信を無料で視聴できる新サービス！配信店舗さんや配信事務所さんは随時募集中です！"
      />
    </Helmet>
  );
};
